@import '@/styles/artifacts.scss';











































































.app-manager-tree-node-attributes-control-tree-node-attribute {
  display: flex;
  align-items: center;
}
.input {
  flex: 1 0 0;
}
.state {
  flex: 0 0 auto;
  margin-left: 20px;
}
.indicator {
  display: block;
}
