@import '@/styles/artifacts.scss';
















































































.domain-ui-ground-plan-spot-placement {
  display: contents;
}
.frame {
  box-sizing: border-box;
  box-shadow: 0 0 0px 6px #ffffffdd;
  border: 2px dashed colorVodafone(red-violet);
  border-radius: 6px;
  padding: 6px;
  width: 52px;
  height: 52px;
  background-color: colorVodafone(white);
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame:hover {
  border: 2px solid colorVodafone(aqua-blue);
}
.frame.dragging {
  border-style: dotted;
  opacity: 0.8;
}
.image {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.separator {
  &:before {
    content: '|';
  }
  margin-left: 5px;
  margin-right: 5px;
}

.h-space {
  margin-top: 10px;
  margin-bottom: 10px;
}
