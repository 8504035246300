@import '@/styles/artifacts.scss';













































































































































































































































.consumption-widget {
  display: flex;
  flex-direction: column;
  position: relative;
}
