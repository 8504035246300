@import '@/styles/artifacts.scss';






















































































.container {
  margin: 10px 0 10px 0;
}
