@import '@/styles/artifacts.scss';












































.app-manager-building-floor:not(:first-child) {
  border-top: 1px solid #ccc;
}
.floor-label {
  font-size: fontSize(fs-50);
  color: colorVodafone(vodafone-grey);
}
.floor-label:not(.roof) {
  margin-bottom: -10px;
}
.floor-label.roof {
  margin-top: -10px;
}
.right {
  background-color: colorVodafone(lighter);
}
.right .floor-label {
  text-align: right;
}
