@import '@/styles/artifacts.scss';


































.detail-box {
  margin: 20px 0px 0px 0px;
}
