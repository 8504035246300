@import '@/styles/artifacts.scss';






























.frame {
  position: relative;
  border: 2px solid;
  border-color: colorVodafone(lighter);
  border-radius: 5px;
  width: 180px;
  min-height: 100px;
  background-color: colorVodafone(lighter);
  cursor: pointer;
}
.frame.invert {
  border-color: colorVodafone(white);
  background-color: colorVodafone(white);
}
.frame:hover {
  border-color: colorVodafone(aqua-blue);
}
.label {
  text-align: center;
  padding: 5px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.gateways {
  width: 160px;
  overflow: hidden;
  margin: auto;
  padding-bottom: 10px;
}
.spots {
  width: 160px;
  overflow: hidden;
  margin: auto;
  padding-bottom: 10px;
}
