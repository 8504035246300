@import '@/styles/artifacts.scss';


























































































































































































































.clickboard-icon {
  height: 1.5em;
  width: auto;
}
.qr-img {
  display: block;
  margin: 0 auto;
  width: 250px;
  height: 250px;
}
