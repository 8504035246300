@import '@/styles/artifacts.scss';




































































































































































































































































































.ground-plan {
  // FIXME need to disable this right now, since firefox does not recalculate
  // the canvas width, if the image is limited in height
  // height: 75vh;
  min-height: 300px;
}
.left {
  height: 100%;
}
.right {
  height: 100%;
}
.scroll {
  height: 100%;
  overflow: auto;
}
.ground-plan-label {
  margin: -3px auto;
  font-weight: 500;
}
