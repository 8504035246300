@import '@/styles/artifacts.scss';






































































































































































































































.qr-image {
  width: 180px;
  height: 180px;
}
.qr-container {
  text-align: center;
}
