@import '@/styles/artifacts.scss';




























































.floors {
  position: relative;
  margin: 0 16px 23px;
  min-height: 100px;
}
.floors:not(.roof) {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.floors::before,
.floors::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -12px;
  left: -8px;
  right: -8px;
  height: 10px;
  border: 1px solid #ccc;
}
.floors::after {
  bottom: -23px;
}
.roof {
  background: url(./roof.svg) right 10px bottom -1px no-repeat;
  padding-right: 325px;
}
.roof::before,
.overground::after {
  left: -16px;
  right: -16px;
}
.underground::after {
  display: none;
}
