@import '@/styles/artifacts.scss';






































































































































































.detail-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
