@import '@/styles/artifacts.scss';
















































































































.title {
  font-size: fontSize(fs-150);
  font-weight: fontWeight(regular-bold);
}
.author {
  white-space: nowrap;
}
