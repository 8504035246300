@import '@/styles/artifacts.scss';





















































.gateway-header {
  margin-bottom: 20px;
}
.dashboard-header {
  margin-top: 0;
  margin-bottom: 0;
}
.gateway-nav {
  margin-bottom: 20px;
}
.identifier {
  color: gray;
}
