@import '@/styles/artifacts.scss';


































.mini-sensor {
  display: flex;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 6px;
  width: 40px;
  height: 40px;
  background-color: colorVodafone(white);
}
.mini-sensor.RED {
  border: 2px solid colorVodafone(vodafone-red);
}
.mini-sensor.GREEN {
  border: 2px solid colorVodafone(digital-green);
}
.mini-sensor:hover {
  border: 2px solid colorVodafone(aqua-blue);
}
.image {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.mini-sensor.badge {
  position: relative;
}
.mini-sensor.badge:before {
  content: attr(data-counter);
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  display: block;
  border-radius: 20%;
  background: colorVodafone(vodafone-red);
  border: 1px solid #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  color: #fff;
  position: absolute;
  top: -10px;
  left: -10px;
}
